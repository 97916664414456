import URLService from "infra/envs/URL.service";
import getErrorMessage from "assets/data/errors";
class SocialMediaURL {
    constructor() {
        this.LINKEDIN_PROFILE_BASE_URL = `https://www.linkedin.com/in`;
        this.COLLEGELINK_PROFILE_BASE_URL = `${URLService.CANDIDATE_COLLEGELINK_PATH}/employer/candidate.php?student=`;
        this.LINQ_PROFILE_BASE_URL = `${URLService.CANDIDATE_LINQ_PATH}/employer/candidate.php?student=`;
    }

    /**
     * Validates the LinkedIn URL by checking domain, recruiter account, and LinkedIn ID.
     *
     * @param {string} url - The LinkedIn URL to be validated.
     *
     * @returns {{isValid: boolean, message: string[]}} Returns an object containing a validity status and an array of error messages.
     * If the URL is valid, 'isValid' is true and 'message' is an empty array.
     * If the URL is not valid, 'isValid' is false and 'message' contains relevant error messages.
     *
     * @example
     *   validateLinkedinInput('https://www.linkedin.com/in/johndoe123/'); // Returns: { isValid: true, message: [] }
     *   validateLinkedinInput('https://www.linkedin.com/recruiter/profile/19093354121,PTS,PTS'); // Returns: { isValid: false, message: ['Recruiter accounts are not supported'] }
     */
    validateLinkedinInput(url) {
        if (!url || !url.length > 0)
            return {
                isValid: false,
                message: [getErrorMessage.social.linkedin.required],
            };

        let message = [];
        let isValid = true;

        const linkedInDomainCheck = this.isLinkedInDomain(url);

        if (!linkedInDomainCheck) {
            message.push(getErrorMessage.social.linkedin.invalid);
            isValid = false;
        }

        const recruiterCheck = this.isRecruiterAccount(url);
        if (recruiterCheck) {
            message.push(getErrorMessage.social.linkedin.recruiter);
            isValid = false;
        }

        const linkedinIdCheck = this.containsLinkedinId(url);
        if (!linkedinIdCheck) {
            message.push(getErrorMessage.social.linkedin.badUrl);
            isValid = false;
        }

        return { isValid, message };
    }

    /**
     * Extracts the LinkedIn ID from a given URL.
     *
     * This function uses string manipulation to parse the URL and extract the LinkedIn ID.
     * The LinkedIn ID is expected to be the part after '/in/' and before the next '/' or '?' in the URL.
     *
     * @param {string} url - The LinkedIn URL from which the LinkedIn ID needs to be extracted.
     *
     * @returns {string|null} Returns the extracted LinkedIn ID as a string if the URL has a valid format.
     * If the URL does not have a valid format, or if the input is not provided, the function returns null.
     *
     * @example
     *   extractLinkedinId('https://www.linkedin.com/in/johndoe123/'); // Returns: 'johndoe123'
     *   extractLinkedinId('https://linkedin.com/in/jane-doe'); // Returns: 'jane-doe'
     *   extractLinkedinId('https://www.linkedin.com/in/johndoe123/?locale=en_US'); // Returns: 'johndoe123'
     */
    extractLinkedinId(url) {
        if (!url) return null;
        // Split the URL at '/in/' to separate the base URL from the LinkedIn ID and other parameters
        const linkedinIdAndParameters = url.split("/in/")[1];

        // The LinkedIn ID is the part before the next '/' or '?' so split at '/' and '?' and take the first part
        const linkedinId = linkedinIdAndParameters.split(/\/|\?/)[0];

        return linkedinId;
    }

    /**
     * Checks if the given URL has 'linkedin.com' as its domain.
     * This function uses regular expressions to parse the URL and check the domain.
     *
     * @param {string} url - The URL to be checked.
     * @returns {boolean} Returns true if the domain of the URL is 'linkedin.com' or a subdomain of 'linkedin.com', otherwise returns false.
     */
    isLinkedInDomain(url) {
        // Add 'https://' at the beginning of the URL if it does not have a protocol
        if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
        }

        const match = url.match(/:\/\/(.[^/]+)/);
        if (match) {
            const domain = match[1];
            return (
                domain === "linkedin.com" || domain.endsWith(".linkedin.com")
            );
        }
        return false;
    }

    /**
     * Checks if the given LinkedIn URL contains '/in/'.
     *
     * The '/in/' segment in a LinkedIn URL usually indicates the presence of a LinkedIn ID.
     *
     * @param {string} url - The LinkedIn URL to be checked.
     * @returns {boolean} Returns true if the URL contains '/in/', otherwise returns false.
     *
     * @example
     *   containsLinkedinId('https://www.linkedin.com/in/johndoe123/'); // Returns: true
     *   containsLinkedinId('https://linkedin.com/company/openai'); // Returns: false
     */
    containsLinkedinId(url) {
        return url.includes("/in/");
    }

    /**
     * Checks if the given LinkedIn URL is a recruiter account.
     * LinkedIn recruiter accounts usually have '/recruiter/' segment in the URL.
     *
     * @param {string} url - The LinkedIn URL to be checked.
     * @returns {boolean} Returns true if the URL contains '/recruiter/', indicating a recruiter account,
     * otherwise returns false.
     *
     * @example
     *   isRecruiterAccount('https://www.linkedin.com/recruiter/profile/19093354121,PTS,PTS'); // Returns: true
     *   isRecruiterAccount('https://www.linkedin.com/in/johndoe123/'); // Returns: false
     */
    isRecruiterAccount(url) {
        return url.includes("/recruiter/");
    }

    /**
     * Checks if the given CollegeLink URL contains a studend id paramater.
     *
     * @param {string} url - The CollegeLink URL to be checked.
     * @returns {boolean} Returns true if the URL contains 'student=',
     * otherwise returns false.
     */
    hasStudentParam(url) {
        return url.includes("student=");
    }

    /**
     * Checks if the given URL has 'collegelink.gr' as its domain.
     * This function uses regular expressions to parse the URL and check the domain.
     *
     * @param {string} url - The URL to be checked.
     * @returns {boolean} Returns true if the domain of the URL is 'collegelink.gr', otherwise returns false.
     */
    isCollegelinkDomain(url) {
        // Add 'https://' at the beginning of the URL if it does not have a protocol
        if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
        }

        const match = url.match(/:\/\/(.[^/]+)/);
        if (match) {
            const domain = match[1];
            return (
                domain === "collegelink.gr" ||
                domain.endsWith(".collegelink.gr")
            );
        }
        return false;
    }

    /**
     * Checks if the given URL has 'linq.co' as its domain.
     * This function uses regular expressions to parse the URL and check the domain.
     *
     * @param {string} url - The URL to be checked.
     * @returns {boolean} Returns true if the domain of the URL is 'linq.co', otherwise returns false.
     */
    isLinqDomain(url) {
        // Add 'https://' at the beginning of the URL if it does not have a protocol
        if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
        }

        const match = url.match(/:\/\/(.[^/]+)/);
        if (match) {
            const domain = match[1];
            return (
                domain === "linq.co" ||
                domain === "app.linq.co" ||
                domain.endsWith("linq.co")
            );
        }
        return false;
    }

    /**
     * Extracts the student ID from a given URL.
     * This function searches the entire URL for a 'student=' parameter followed by one or more digits,
     * which it interprets as the student ID. If such a parameter is found, its value is returned.
     * If not, the function returns null.
     *
     * @param {string} url - The URL from which to extract the student ID.
     * @returns {(string|null)} The extracted student ID, or null if no 'student=' parameter is found in the URL.
     * @example
     * // Returns '177162'
     * extractCollegelinkId('www.collegelink.gr/student=177162')
     *
     * // Returns '177162'
     * extractCollegelinkId('https://www.collegelink.gr/employer/candidate.php?student=177162')
     *
     * // Returns null
     * extractCollegelinkId('https://www.collegelink.gr/employer/candidate.php')
     */
    extractCollegelinkId(url) {
        const match = url?.match(/student=([0-9]+)/);
        return match ? match[1] : null;
    }

    /**
     * Extracts the student ID from a given URL.
     * This function searches the entire URL for a 'student=' parameter followed by one or more digits,
     * which it interprets as the student ID. If such a parameter is found, its value is returned.
     * If not, the function returns null.
     *
     * @param {string} url - The URL from which to extract the student ID.
     * @returns {(string|null)} The extracted student ID, or null if no 'student=' parameter is found in the URL.
     * @example
     */
    extractLinqId(url) {
        const match = url?.match(/student=([0-9]+)/);
        debugger;
        return match ? match[1] : null;
    }
}

const service = new SocialMediaURL();
export default service;
